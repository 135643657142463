<template>
  <section class="section8 text-center">
      <div class="container">
        <div class="row">
          <div class="col-12 pr-0">
            <div class="tableResult" :style="tableResultStyle">
              <ul >
                <li><strong :style="strongStyle()">120</strong></li>
                <li><strong :style="strongStyle()">110</strong></li>
                <li><strong :style="strongStyle()">100</strong></li>
                <li><strong :style="strongStyle()">90</strong></li>
                <li><strong :style="strongStyle()">80</strong></li>
                <li><strong :style="strongStyle()">70</strong></li>
                <li><strong :style="strongStyle()">60</strong></li>
                <li><strong :style="strongStyle()">50</strong></li>
                <li><strong :style="strongStyle()">40</strong></li>
                <li><strong :style="strongStyle()">30</strong></li>
                <li><strong :style="strongStyle()">20</strong></li>
              </ul>
            </div>
          </div>  
          <div class="col-12 pl-0">
            <div>
              <p class="lightblueTxt mb-4">{{ $t('main.result.heading') }}</p>
              <div class="lightblue_btn_gradient inspiratory-btn" style="background: url('images/blue-bg2.png');">
                <a href="javascript:void(0)"><img src="images/inspiratory-img.png" alt="Table Result" class="d-block img-fluid"/> 
                  <div class="middleTxt">
                    <span>{{ maxFlowDampened }}</span> <strong>L/min</strong>
                  </div>  
                </a>
              </div>
            </div>  
          </div>
        </div>  
        <div class="row"> 
          <div class="col-12">
            <button class="btn blueBtn2 mt-4" @click="$router.push('/main/select-inhaler')">{{ $t('main.result.view_result') }}</button>
          </div>
        </div>
      </div>
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      selectedDevice: 'inhaler/selectedDevice',
      maxFlowDampened: 'inhaler/maxFlowDampened',
      result: 'inhaler/result',
    }),
    tableResultStyle() {
      var backgroundPercenatge = 0;
      var percentagePerLine = 0;
      var range = 8;
      if(this.maxFlowDampened == 0) {
        backgroundPercenatge = 100;
      } else if(this.maxFlowDampened >=1 && this.maxFlowDampened <=20) {
        range = (this.maxFlowDampened - 20) * (20 / 8);
        backgroundPercenatge = (100 - range);
      } else if(this.maxFlowDampened >=21 && this.maxFlowDampened <=30) {
        percentagePerLine = 8;
        range = (this.maxFlowDampened - 20) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=31 && this.maxFlowDampened <=40) {
        percentagePerLine = 16;
        range = (this.maxFlowDampened - 30) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=41 && this.maxFlowDampened <=50) {
        percentagePerLine = 24;
        range = (this.maxFlowDampened - 40) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=51 && this.maxFlowDampened <=60) {
        percentagePerLine = 32;
        range = (this.maxFlowDampened - 50) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=61 && this.maxFlowDampened <=70) {
        percentagePerLine = 40;
        range = (this.maxFlowDampened - 60) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=71 && this.maxFlowDampened <=80) {
        percentagePerLine = 48;
        range = (this.maxFlowDampened - 70) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=81 && this.maxFlowDampened <=90) {
        percentagePerLine = 56;
        range = (this.maxFlowDampened - 80) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=91 && this.maxFlowDampened <=100) {
        percentagePerLine = 64;
        range = (this.maxFlowDampened - 90) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=101 && this.maxFlowDampened <=110) {
        percentagePerLine = 72;
        range = (this.maxFlowDampened - 100) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=111 && this.maxFlowDampened <=120) {
        percentagePerLine = 80;
        range = (this.maxFlowDampened - 110) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      } else if(this.maxFlowDampened >=121) {
        percentagePerLine = 88;
        range = (this.maxFlowDampened - 120) * 0.8;
        backgroundPercenatge = 100 - (range + percentagePerLine);
      }
      let tableHeight = '360 px';
      var cssRule = 'height:'+tableHeight+';background: linear-gradient(to bottom, transparent '+backgroundPercenatge+'%, #bee5e6 '+backgroundPercenatge+'%);';
      return cssRule;
    }
  },
  methods: {
    strongStyle() {
      var cssRule = 'padding: 3px 8px;';
      //var cssRule = 'height:'+tableHeight+';background: linear-gradient(to bottom, transparent '+backgroundPercenatge+'%, #bee5e6 '+backgroundPercenatge+'%);';
      return cssRule;

    }
  }
}

</script>